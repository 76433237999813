import { Route } from "badmagic/dist/types";

const basePath = "/support/v1";

const companyRoutes: Route[] = [
  {
    name: "Create a company",
    path: `${basePath}/companies`,
    method: "post",
    body: [
      { name: "name", required: true },
      { name: "organization_remote_ids", array: true },
      { name: "installation_type" },
    ],
  },
  {
    name: "Search companies",
    path: `${basePath}/companies`,
    method: "get",
    qsParams: [
      { name: "id" },
      { name: "ids" },
      { name: "name" },
      { name: "org_id" },
    ],
  },
];

export default companyRoutes;
